.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-container {
  background: #f8f9fa;
  border-radius: 5px;
  padding: 55px 70px !important;
  margin-top: 20%;
  box-shadow: 1px 1px 8px -4px #b5b5b5;
  padding-top: 20px !important;
}

.register-container {
  background: #f8f9fa;
  border-radius: 5px;
  padding: 55px 70px !important;
  margin-top: 10%;
  box-shadow: 1px 1px 8px -4px #b5b5b5;
  padding-top: 20px !important;
}

.login-container h3,
.register-container h3 {
  text-align: center;
  margin-bottom: 25px;
  color: #929292;
}

.button-login {
  background: #1b6ec2;
  color: #ffffff;
  border: 0;
  display: block;
  width: 100%;
  border-radius: 5px;
  padding: 8px 10px;
}

.page-link:disabled {
  opacity: 0.4;
}

.page-link:hover:disabled {
  background: #ffffff;
}

.table-responsive {
  max-height: 500px !important;
}

.table-responsive table {
  border-collapse: separate;
  border-spacing: 0;
}

.table-responsive thead tr:nth-child(1) th {
  position: sticky;
  top: 0;
  z-index: 10;
}

.table-responsive thead tr:nth-child(1) th:not(.current-sort) {
  background-color: #ffffff;
}

.table-responsive .table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
}

.count {
  font-weight: 700;
}

.nowrap-td {
  white-space: nowrap;
}

.minwidth-td {
  min-width: 300px;
}

.pagination {
  margin-top: 20px;
}

td {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.text-overflow-th {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.small-th-width {
  width: 100px;
}

.medium-th-width {
  width: 120px;
}

.large-th-width {
  width: 130px;
}

.page-link.active {
  background: #0366d6;
  color: #ffffff;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #e7f2ff !important;
}

.spinner-back {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #2323232e;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.spinner-back .spinner-border {
  width: 70px;
  height: 70px;
  border-width: 0.4em;
  color: #1b6ec2;
}

.user-info-item .title {
  font-size: 18px;
  font-weight: 700;
}

.user-info-item .value {
  font-size: 18px;
  font-weight: 500;
}

.container-layout {
  padding-bottom: 20px;
}

.brand {
  font-weight: 700;
}

.mark-brand {
  color: #0366d6;
}

.header-title {
  padding-bottom: 20px;
}

.nav-header {
  padding: 15px 0px !important;
}

.nav-header > .container {
  padding: 0 !important;
}

.header-title .underline {
  display: inline-block;
  border-bottom: 3px solid #0366d6;
  width: 20px;
  margin-left: 5px;
}

header .icon-header {
  width: 25px;
  color: #121416;
  height: 20px;
  margin-top: -4px;
}

.nav-item.active {
  background: #0366d6;
  border-radius: 6px;
}

.nav-item.active .icon-header,
.nav-item.active .title-header {
  color: #ffffff !important;
}

.add-user-container .nav-tabs {
  border-bottom: none;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.add-user-container .nav-link {
  border: none !important;
  font-size: 17px !important;
  font-weight: 500 !important;
  color: #232323 !important;
  background: #e7f2ff !important;
  border-radius: 0px !important;
  padding: 5px 85px !important;
  cursor: pointer;
}

.add-user-container .nav-link.active {
  background: #0366d6 !important;
  color: #ffffff !important;
}

.container-layout .list-group-item-info {
  background: #e7f2ff !important;
  color: #020202;
}

.mark-required {
  color: #f31010;
}

.form-block {
  padding: 15px 30px 2px;
  background: #e7f2ff;
  border-radius: 5px;
  box-shadow: 1px 1px 3px -2px #636363;
  padding: 15px 100px 0px !important;
}

.actions {
  padding: 21px 0px;
  border-top: 1px solid #d2d2d2;
}

.form-block .actions .buttons-pagination {
  border: none;
  background: #0366d6;
  color: #ffffff;
  padding: 5px 15px;
  width: 40%;
  border-radius: 38px;
  margin-right: 10px;
}

.button-filter {
  background: #0366d6;
  color: #ffffff;
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
}

.button-filter-clear {
  background: #ff6161;
  color: #ffffff;
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
}

.tools-table {
  padding: 15px 20px;
  background: #e7f2ff;
  margin-bottom: 20px;
}

.tools-table label {
  font-size: 13px;
  font-weight: 500;
}

.form-block .actions .button-submit {
  display: block;
  border: none;
  background: #0366d6;
  color: #ffffff;
  padding: 5px 15px;
  width: 100%;
  border-radius: 38px;
  font-size: 20px;
  font-weight: 500;
}

.current-sort.current-sort-up {
  border-top: 2px solid #232323 !important;
}

.current-sort.current-sort-down {
  border-bottom: 2px solid #232323 !important;
}

.current-sort {
  border-top: 2px solid transparent !important;
  border-bottom: 2px solid transparent !important;
  background: #f5f5f5;
  color: #3c3c3c;
  cursor: pointer;
}

.sorting:hover {
  background: #f5f5f5 !important;
}

textarea {
  min-height: 143px;
}

.sorting {
  cursor: pointer;
}

.form-block .actions .button-submit:disabled,
.form-block .actions .buttons-pagination:disabled,
.button-filter:disabled {
  opacity: 0.2;
}

.attachment-link span {
  text-decoration: underline;
  color: #0366d6;
  font-weight: 500;
  cursor: pointer;
}

.empty-row {
  text-align: center;
  font-size: 21px;
  font-weight: 700;
}

.failure {
  font-weight: 500;
  text-decoration: underline;
  color: #dc3545;
  cursor: pointer;
}

.button-edit {
  background: #0366d6;
  color: #ffffff;
  border: none;
  border-radius: 4px;
}

.item-placeholder {
  color: #5d5d5d;
  font-size: 13px;
  margin-bottom: 5px;
}

.button-edit a {
  color: #ffffff;
  padding: 7px 17px;
  display: block;
}

.button-edit a:hover {
  color: #ffffff;
  text-decoration: none;
}

.success {
  font-weight: 500;
  color: #28a745;
}

.error-outline {
  border-color: #f31010 !important;
}

.error-field {
  color: #f31010;
  font-weight: 500;
  font-size: 12px;
}

.disabled-tag {
  background: #8e8e8e !important;
  padding-right: 14px !important;
}

/*

*/

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px;
}
.checkbox + label {
  position: relative;
  padding: 0 0 0 60px;
  cursor: pointer;
}
.checkbox + label:before {
  content: "off";
  position: absolute;
  top: -4px;
  left: 0;
  width: 50px;
  height: 26px;
  border-radius: 13px;
  background: #cdd1da;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  font-size: 12px;
  text-align: right;
  padding-right: 7px;
  padding-top: 3px;
  color: #a2a2a2;
}
.checkbox + label:after {
  content: "";
  position: absolute;
  top: -2px;
  left: 2px;
  width: 22px;
  height: 22px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: 0.2s;
}
.checkbox:checked + label:before {
  background: #0366d6;
  content: "on";
  text-align: left;
  padding-left: 7px;
  padding-top: 3px;
  color: #ffffff;
}
.checkbox:checked + label:after {
  left: 26px;
}
.checkbox:focus + label:before {
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2),
    0 0 0 3px rgba(211, 230, 255, 0.7);
}

.sub-title {
  font-size: 10px;
  position: relative;
  top: -7px;
  left: -11px;
}

/*

*/

.radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 7px;
}
.radio + label {
  position: relative;
  padding: 0 0 0 35px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
}
.radio + label:before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  width: 22px;
  height: 22px;
  border: 1px solid #cdd1da;
  border-radius: 50%;
  background: #fff;
}
.radio + label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #0366d6;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: 0.2s;
}
.radio:checked + label:after {
  opacity: 1;
}
.radio:focus + label:before {
  box-shadow: 0 0 0 3px rgba(211, 230, 255, 0.7);
}

.form-block .form-group label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 2px !important;
}

.form-block .form-group {
  margin-bottom: 1.4rem;
}

.checkbox-container-form {
  position: relative;
  top: -10px;
}

/*

*/
.input-extenstion-list-col {
  padding-right: 0 !important;
}

.button-extension-list-col {
  padding: 0 15px 0 0 !important;
}

.input-extenstion-list {
  height: 100% !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: none !important;
}

.button-extension-list {
  width: 100% !important;
  display: block !important;
  height: 100% !important;
  border: none !important;
  background: #0366d6 !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  border: 1px solid #ced4da !important;
  border-left: none !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.tag {
  background: #0366d6;
  display: inline-block;
  color: #ffffff;
  border-radius: 1200px;
  padding: 1px 6px 1px 14px;
  font-size: 13px;
  font-weight: 500;
  margin-right: 10px;
}

.tag .close-icon {
  color: #ffffff;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  position: relative;
  top: -1px;
}

.without-margin {
  margin-bottom: 4px !important;
}

.popover-header {
  color: #ffffff !important;
  padding: 8px 40px !important;
  background: #0366d6 !important;
}

.popover-header:before {
  border-bottom: none !important;
}

.popover-body {
  padding: 15px 10px !important;
}

.attach-title {
  font-weight: 700;
  font-size: 15px;
  margin-right: 15px;
}

.icon-attach-success {
  color: #0366d6;
  font-size: 25px;
}

.icon-attach-error {
  color: #ff6161;
  font-size: 25px;
}

/*
user info
*/

.header-user-info {
  position: relative;
}

.header-user-info .header-title {
  position: relative;
}

.header-user-info .header-button-edit {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20px;
  margin-top: 3px;
  background: #0366d6;
  color: #ffffff;
  border-radius: 4px;
  border: none;
}

.header-user-info .header-button-edit a {
  color: #ffffff;
  padding: 6px 25px;
  display: block;
}

.header-user-info .header-button-edit a:hover {
  text-decoration: none;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    width: 80% !important;
    max-width: 1500px !important;
  }
}
